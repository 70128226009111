import React, { Fragment, ReactNode } from 'react';
import './index.css';
import CopyrightNotice from '../CopyRightNotice';
interface LayoutProps {
	children: ReactNode;
}

function Layout({ children }: Readonly<LayoutProps>) {
	return (
		<Fragment>
			<main className="layout-container">{children}</main>
			<CopyrightNotice />
		</Fragment>
	);
}

export default Layout;
