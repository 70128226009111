import React, { Suspense } from 'react';
import './App.css';
import Layout from './components/layout';
import Navbar from './components/layout/Navbar';
import Loading from './components/Common/Loading';

// Lazy load components
const Hero = React.lazy(() => import('./components/Hero'));
const About = React.lazy(() => import('./components/About'));
const Socials = React.lazy(() => import('./components/Socials'));
const Experience = React.lazy(() => import('./components/Experience'));
const Projects = React.lazy(() => import('./components/Projects'));

function App() {
	return (
		<Layout>
			<Suspense fallback={<Loading />}>
				<div className="app-components">
					<div className="left-section">
						<Hero />
						<Navbar />
						<Socials />
					</div>
					<div className="right-section">
						<div id="about" className="section">
							<About />
						</div>
						<div id="experience" className="section">
							<Experience />
						</div>
						<div id="projects" className="section">
							<Projects />
						</div>
					</div>
				</div>
			</Suspense>
		</Layout>
	);
}

export default App;
