import React from 'react';
import './index.css'; // You can customize the styles in this file

const Loading: React.FC = () => {
	return (
		<div className="loading-container">
			<div className="loading-spinner"></div>
			<div className="loading-text">Loading...</div>
		</div>
	);
};

export default Loading;
