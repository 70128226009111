import React, { useState, useEffect } from 'react';
import './index.css';

const Navbar: React.FC = () => {
	const [activeSection, setActiveSection] = useState<string>('');

	useEffect(() => {
		const handleScroll = () => {
			const sections = document.querySelectorAll('.section');
			let currentSection = '';

			sections.forEach((section) => {
				const sectionTop = section.getBoundingClientRect().top;
				if (sectionTop < window.innerHeight / 2) {
					currentSection = section.id;
				}
			});

			setActiveSection(currentSection || 'about'); // Set to 'about' if no section is active
		};

		// Function to handle scrolling to the correct section when the page loads with a hash
		const handleHashScroll = () => {
			const hash = window.location.hash.substring(1); // Get the hash from the URL without the #
			const section = document.getElementById(hash);
			if (section) {
				const sectionTop = section.getBoundingClientRect().top + window.scrollY - 100;
				window.scrollTo({
					top: sectionTop,
					behavior: 'smooth',
				});
				setActiveSection(hash); // Update activeSection with the hash
			}
		};

		// Call handleHashScroll when the component mounts to handle scrolling to the correct section
		if (!window.location.hash) {
			handleScroll();
		} else {
			handleHashScroll();
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleNavClick = (sectionId: string) => {
		const section = document.getElementById(sectionId);
		if (section) {
			const sectionTop = section.getBoundingClientRect().top + window.scrollY - 100;
			window.scrollTo({
				top: sectionTop,
				behavior: 'smooth',
			});

			// Update URL with section ID
			window.history.pushState({}, '', `#${sectionId}`);
		}
	};

	return (
		<nav className="navbar">
			<ul>
				<li className={activeSection === 'about' ? 'active' : ''} onClick={() => handleNavClick('about')}>
					&mdash;&mdash; ABOUT
				</li>
				<li
					className={activeSection === 'experience' ? 'active' : ''}
					onClick={() => handleNavClick('experience')}
				>
					&mdash;&mdash; EXPERIENCE
				</li>
				<li className={activeSection === 'projects' ? 'active' : ''} onClick={() => handleNavClick('projects')}>
					&mdash;&mdash; PROJECTS
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
