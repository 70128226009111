import React from 'react';
import './index.css'; // Import your CSS file

const CopyrightNotice = () => {
	const currentYear = new Date().getFullYear();

	return (
		<footer className="copyright">
			<p>&copy; {currentYear} Mahmoud Mraisi</p>
			<p>Theme Inspired By Brittany Chiang</p>
		</footer>
	);
};

export default CopyrightNotice;
